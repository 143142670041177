import React, { useState } from 'react';
import { Check, CreditCard, X, AlertCircle } from 'lucide-react';

interface Plan {
  id: string;
  name: string;
  price: number;
  billingPeriod: 'monthly' | 'yearly';
  features: string[];
  recommended: boolean;
  maxVehicles: number | 'Unlimited';
}

const plans: Plan[] = [
  {
    id: 'basic',
    name: 'Basic',
    price: 29,
    billingPeriod: 'monthly',
    maxVehicles: 5,
    features: [
      'Up to 5 vehicles',
      'Basic maintenance tracking',
      'Email notifications',
      'Standard support',
      'Vehicle registration tracking',
      'Insurance expiry alerts',
    ],
    recommended: false,
  },
  {
    id: 'professional',
    name: 'Professional',
    price: 79,
    billingPeriod: 'monthly',
    maxVehicles: 20,
    features: [
      'Up to 20 vehicles',
      'Advanced maintenance tracking',
      'SMS & Email notifications',
      'Priority support',
      'Custom alerts',
      'Analytics dashboard',
      'Maintenance history',
      'Document storage',
      'Fuel tracking',
      'Service provider management',
    ],
    recommended: true,
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    price: 199,
    billingPeriod: 'monthly',
    maxVehicles: 'Unlimited',
    features: [
      'Unlimited vehicles',
      'Full fleet management',
      'All notifications channels',
      '24/7 Premium support',
      'Custom integration',
      'Advanced analytics',
      'API access',
      'White-label options',
      'Dedicated account manager',
      'Custom reporting',
      'Multi-user access',
      'Role-based permissions',
    ],
    recommended: false,
  },
];

export default function Subscription() {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly'>('monthly');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handlePlanSelect = (planId: string) => {
    setSelectedPlan(planId);
    setShowConfirmation(true);
  };

  const calculatePrice = (basePrice: number) => {
    if (billingPeriod === 'yearly') {
      return (basePrice * 10).toFixed(2); // 2 months free
    }
    return basePrice.toFixed(2);
  };

  return (
    <div className="space-y-6 pb-12">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-gray-900">Subscription Plans</h1>
        <p className="mt-2 text-gray-600">
          Choose the perfect plan for your fleet management needs
        </p>
      </div>

      {/* Billing Period Toggle */}
      <div className="flex justify-center items-center space-x-4 my-8">
        <div className="bg-white rounded-lg p-1 inline-flex shadow-sm border">
          {(['monthly', 'yearly'] as const).map((period) => (
            <button
              key={period}
              onClick={() => setBillingPeriod(period)}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                billingPeriod === period
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>
        {billingPeriod === 'yearly' && (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            Save 16%
          </span>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
        {plans.map((plan) => (
          <div
            key={plan.id}
            className={`bg-white rounded-lg shadow-sm p-6 border relative ${
              plan.recommended ? 'border-blue-500' : 'border-gray-200'
            }`}
          >
            {plan.recommended && (
              <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                Recommended
              </span>
            )}
            <div className="mt-4">
              <h2 className="text-xl font-bold text-gray-900">{plan.name}</h2>
              <div className="mt-4">
                <span className="text-4xl font-bold text-gray-900">
                  ${calculatePrice(plan.price)}
                </span>
                <span className="text-gray-500 ml-2">
                  /{billingPeriod === 'monthly' ? 'mo' : 'yr'}
                </span>
              </div>
              <p className="mt-2 text-sm text-gray-500">
                {plan.maxVehicles === 'Unlimited'
                  ? 'Unlimited vehicles'
                  : `Up to ${plan.maxVehicles} vehicles`}
              </p>
            </div>

            <ul className="mt-6 space-y-4">
              {plan.features.map((feature) => (
                <li key={feature} className="flex items-start">
                  <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                  <span className="text-gray-600 text-sm">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => handlePlanSelect(plan.id)}
              className={`mt-8 w-full px-4 py-2 rounded-lg flex items-center justify-center transition-colors ${
                plan.recommended
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }`}
            >
              <CreditCard className="w-5 h-5 mr-2" />
              Select Plan
            </button>
          </div>
        ))}
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <div className="flex justify-between items-start mb-4">
              <div className="flex items-center">
                <AlertCircle className="w-6 h-6 text-blue-600 mr-2" />
                <h3 className="text-lg font-semibold">Confirm Subscription</h3>
              </div>
              <button
                onClick={() => setShowConfirmation(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">
              You are about to subscribe to the{' '}
              {plans.find((p) => p.id === selectedPlan)?.name} plan. This will be billed{' '}
              {billingPeriod}. Would you like to continue?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Handle subscription confirmation
                  setShowConfirmation(false);
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Confirm Subscription
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
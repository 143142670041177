import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthGuard } from '../components/AuthGuard';
import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Welcome from '../pages/Welcome';
import Vehicles from '../pages/Vehicles';
import Drivers from '../pages/Drivers';
import InsurancePage from '../pages/Insurance';
import Alerts from '../pages/Alerts';
import Subscription from '../pages/Subscription';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      
      <Route path="/" element={
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      } />
      
      <Route path="/vehicles" element={
        <AuthGuard>
          <Vehicles />
        </AuthGuard>
      } />
      
      <Route path="/vehicles/:id" element={
        <AuthGuard>
          <Vehicles />
        </AuthGuard>
      } />
      
      <Route path="/drivers" element={
        <AuthGuard>
          <Drivers />
        </AuthGuard>
      } />
      
      <Route path="/drivers/:id" element={
        <AuthGuard>
          <Drivers />
        </AuthGuard>
      } />

      <Route path="/insurance" element={
        <AuthGuard>
          <InsurancePage />
        </AuthGuard>
      } />
      
      <Route path="/insurance/:id" element={
        <AuthGuard>
          <InsurancePage />
        </AuthGuard>
      } />
      
      <Route path="/alerts" element={
        <AuthGuard>
          <Alerts />
        </AuthGuard>
      } />
      
      <Route path="/subscription" element={
        <AuthGuard>
          <Subscription />
        </AuthGuard>
      } />

      {/* Redirect root to welcome for non-authenticated users */}
      <Route path="*" element={<Navigate to="/welcome" replace />} />
    </Routes>
  );
}
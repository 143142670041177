import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Car,
  Bell,
  CreditCard,
  LayoutDashboard,
  Menu,
  X,
  LogOut,
  Users,
  Shield,
} from 'lucide-react';
import { getActiveAlertsCount } from '../data/mockData';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const activeAlerts = getActiveAlertsCount();

  // Don't show layout on welcome page
  if (location.pathname === '/welcome') {
    return <>{children}</>;
  }

  const menuItems = [
    { icon: LayoutDashboard, label: 'Dashboard', path: '/' },
    { icon: Car, label: 'Vehicles', path: '/vehicles' },
    { icon: Users, label: 'Drivers', path: '/drivers' },
    { icon: Shield, label: 'Insurance', path: '/insurance' },
    { 
      icon: Bell, 
      label: 'Alerts', 
      path: '/alerts',
      badge: activeAlerts > 0 ? activeAlerts : undefined 
    },
    { icon: CreditCard, label: 'Subscription', path: '/subscription' },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/welcome');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  if (!user) {
    return <div className="min-h-screen">{children}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile menu button */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="fixed top-4 left-4 z-50 lg:hidden bg-white p-2 rounded-lg shadow-md"
      >
        {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
      >
        <div className="h-full px-3 py-4 overflow-y-auto bg-white border-r">
          <div className="flex items-center mb-8 p-2">
            <Car className="w-8 h-8 text-blue-600" />
            <span className="ml-3 text-xl font-bold">FleetManager</span>
          </div>
          
          <nav className="space-y-2">
            {menuItems.map((item) => {
              const Icon = item.icon;
              return (
                <button
                  key={item.path}
                  onClick={() => {
                    navigate(item.path);
                    setIsSidebarOpen(false);
                  }}
                  className="flex items-center justify-between w-full p-3 text-gray-700 rounded-lg hover:bg-gray-100 group"
                >
                  <div className="flex items-center">
                    <Icon className="w-5 h-5" />
                    <span className="ml-3">{item.label}</span>
                  </div>
                  {item.badge && (
                    <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">
                      {item.badge}
                    </span>
                  )}
                </button>
              );
            })}
          </nav>
        </div>
      </aside>

      {/* Main content */}
      <div className="lg:ml-64 p-4">
        <header className="bg-white shadow-sm mb-6 p-4 rounded-lg flex justify-between items-center">
          <h1 className="text-xl font-semibold text-gray-800">Vehicle Management System</h1>
          <div className="flex items-center gap-4">
            <span className="text-sm text-gray-600">{user.email}</span>
            <button
              onClick={handleLogout}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <LogOut className="w-5 h-5" />
            </button>
          </div>
        </header>
        
        <main className="container mx-auto">{children}</main>
      </div>
    </div>
  );
}
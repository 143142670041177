import React from 'react';
import { Shield, Calendar, FileText, AlertTriangle } from 'lucide-react';
import { format, differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export interface InsurancePolicy {
  id: number;
  vehicleId: number;
  vehicleName: string;
  provider: string;
  policyNumber: string;
  startDate: string;
  expirationDate: string;
  coverageDetails: string;
  documentUrl?: string;
  status: 'active' | 'expiring' | 'expired';
}

interface InsuranceListProps {
  policies: InsurancePolicy[];
}

export default function InsuranceList({ policies }: InsuranceListProps) {
  // Rest of the component remains the same, just update the type references
  const navigate = useNavigate();

  const getStatusColor = (status: InsurancePolicy['status']) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'expiring':
        return 'bg-yellow-100 text-yellow-800';
      case 'expired':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getExpirationWarning = (expirationDate: string) => {
    const daysUntilExpiration = differenceInDays(
      new Date(expirationDate),
      new Date()
    );
    if (daysUntilExpiration < 0) {
      return { message: 'Expired', icon: AlertTriangle, color: 'text-red-500' };
    }
    if (daysUntilExpiration <= 30) {
      return {
        message: `Expires in ${daysUntilExpiration} days`,
        icon: AlertTriangle,
        color: 'text-yellow-500',
      };
    }
    return { message: 'Active', icon: Shield, color: 'text-green-500' };
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold text-gray-900">Insurance Policies</h2>
          <button
            onClick={() => navigate('/insurance/add')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Add Policy
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Vehicle
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Provider & Policy
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Coverage Period
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Documents
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {policies.map((policy) => {
              const expiration = getExpirationWarning(policy.expirationDate);
              const ExpirationIcon = expiration.icon;
              
              return (
                <tr
                  key={policy.id}
                  onClick={() => navigate(`/insurance/${policy.id}`)}
                  className="hover:bg-gray-50 cursor-pointer"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {policy.vehicleName}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      {policy.provider}
                    </div>
                    <div className="text-sm text-gray-500">
                      Policy: {policy.policyNumber}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 text-gray-400 mr-2" />
                      <div>
                        <div className="text-sm text-gray-900">
                          {format(new Date(policy.startDate), 'MMM d, yyyy')}
                        </div>
                        <div className="text-sm text-gray-500">
                          to {format(new Date(policy.expirationDate), 'MMM d, yyyy')}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <ExpirationIcon className={`w-4 h-4 mr-2 ${expiration.color}`} />
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                          policy.status
                        )}`}
                      >
                        {expiration.message}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {policy.documentUrl && (
                      <FileText className="w-5 h-5 text-gray-400" />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}